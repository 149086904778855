/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Breadcrumbs from '../components/Common/Breadcrumbs/Breadcrumbs';
import Articles from '../components/Common/Articles/Articles';

import { PAGES, RUBRICS_TITLE, RUBRICS_DESCRIPTION } from '../constants/Languages';
import { GLOBAL_CREDIT, BLOG, RUBRIC } from '../constants/SEOTokens';
import layoutContext from '../components/layoutContext';
import cultToPath from '../components/cult-to-path';

const Rubric = ({ pageContext, data }) => {
  const { allMarkdownRemark } = data;
  const { edges, node } = allMarkdownRemark;
  const { rubric, cult, rubricName } = pageContext;
  useContext(layoutContext).setCult(cult);
  const rubricPages = edges.reduce((pages, page) => {
    const { node: { frontmatter: {
      headLine, path, category, date, lang }
    } } = page;
    if (lang === cult && (page.node.frontmatter.rubrics || []).includes(rubricName) && page.node.frontmatter.image && category === 'articles') {
      pages.push({
        headLine, path, date,
        image: page.node.frontmatter.image.childImageSharp.resize.src,
        rubrics: page.node.frontmatter.rubrics
      });
    }
    return pages;
  }, []);

  const metaTitle = `${RUBRIC}: ${rubric[cult]}. ${RUBRICS_TITLE[cult]}`;
  const metaDescription = `${BLOG} ${GLOBAL_CREDIT} - ${RUBRIC}:  ${rubric[cult]} ${RUBRICS_DESCRIPTION[cult]}`;

  return (
    <div>
      <Seo
        title={metaTitle}
        path={`${cult === 'ru' ? '' : '/uk'}/novosti/tag/${rubricName}`}
        description={metaDescription}
        disableTitlePostfix={node?.frontmatter?.disableTitlePostfix}
      />
      <Breadcrumbs
        category={PAGES.article[cult]}
        post={rubric[cult]}
        postPath={`${cultToPath(cult)}/novosti/tag/${rubricName}`}
        categoryPath={`${cultToPath(cult)}/category/stati`}
      />
      <Articles posts={rubricPages} title={rubric[cult]} />
    </div>
  );
};

Rubric.propTypes = {
  pageContext: PropTypes.shape({
    rubric: PropTypes.object.isRequired
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired
        }).isRequired
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.shape({
          fieldValue: PropTypes.string.isRequired
        })
      })
    })
  })
};

export default Rubric;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000,
      sort: {fields: [frontmatter___date], order: DESC},
      filter: {fileAbsolutePath: {regex: "/content/news/"}}
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            rubrics
            headLine
            category
            lang
            date
            disableTitlePostfix
            image {
              childImageSharp {
                resize(width: 350, height: 220) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
