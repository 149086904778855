import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import layoutContext from '../../layoutContext';

import { TEMPLATE_TOKENS, MONTHS } from '../../../constants/Languages';
const Rubrics = require('../../../constants/Rubrics');


import './Article.scss';


const Article = ({ headLine, path, date, image, rubrics }) => {
  const { cult } = useContext(layoutContext);
  const { readMore } = TEMPLATE_TOKENS[cult];
  const postRubrics = [];

  const year = date.slice(0, 4);
  const number = date.slice(8, 10);
  const month = MONTHS[cult][+date.slice(5, 7) - 1];
  const fotmatDate = `${number} ${month} ${year}`;


  Object.keys(Rubrics).forEach(key => {
    rubrics.forEach(rubric => {
      if (key === rubric) {
        postRubrics.push(Rubrics[key][cult]);
      }
    });
  });


  return (
    <Link to={path} className='article-post'>
      <div className='read-more'>{`${readMore} »`}</div>
      <img
        className='article-image' src={image}
        alt={headLine}
      />
      <span className='article-date'>{fotmatDate}</span>
      <h3 className='post-description-title'>{headLine}</h3>
      <div className='article-rubrics'>
        {postRubrics.map(rubric => (
          <p key={rubric} className='article-rubric'>{rubric}</p>
        ))}
      </div>
    </Link>
  );
};

export default Article;

Article.propTypes = {
  headLine: PropTypes.string,
  path: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  rubrics: PropTypes.array,
};


