import React, { useContext } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './Breabcrumps.scss';
import { TEMPLATE_TOKENS } from '../../../constants/Languages';
import layoutContext from '../../layoutContext';

const Breadcrumbs = ({ category, post, postPath, categoryPath }) => {
  const { cult } = useContext(layoutContext);
  const tokens = TEMPLATE_TOKENS[cult];
  return (
    <div className='breadcrumbs'>
      <Link className='breadcrumb' to={`/${cult === 'ru' ? '' : 'uk'}/`}>
        {tokens.mainPage}
      </Link>
      {'>'}
      <Link className='breadcrumb' to={categoryPath}>
        {category}
      </Link>
      {'>'}
      <Link className='breadcrumb' to={postPath}>
        {post}
      </Link>
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  category: PropTypes.string,
  post: PropTypes.string,
  postPath: PropTypes.string,
  categoryPath: PropTypes.string
};
