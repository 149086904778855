module.exports = { 
  'creditcards': { 'ru': 'Кредитные карты', 'uk': 'Кредитні картки' }, 
  'microcredit': { 'ru': 'Микрокредиты', 'uk': 'Мікрокредити' }, 
  'credit-history': { 'ru': 'Кредитная история', 'uk': 'Кредитна історія' }, 
  'secure': { 'ru': 'Безопасность', 'uk': 'Безпека' }, 
  'credit-bank': { 'ru': 'Кредиты в банке', 'uk': 'Кредити у банку' }, 
  'sovety': { 'ru': 'Советы', 'uk': 'Поради' },
  'banks': { 'ru': 'Банки', 'uk': 'Банки' }, 
  'money': { 'ru': 'Деньги', 'uk': 'Гроші' },
  'credit-sovety': { 'ru': 'Кредитные советы', 'uk': 'Кредитні поради' } 
};
