import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './Pagination.scss';
import layoutContext from '../../layoutContext';
import { TEMPLATE_TOKENS } from '../../../constants/Languages';

const Pagination = ({ page, changePage, pages }) => {
  const { cult } = useContext(layoutContext);
  const { prevPage, nextPage } = TEMPLATE_TOKENS[cult];

  if (pages.length >= 10 && page < 10) {
    pages = pages.slice(0, 10);
  } else if (page >= 10) {
    pages = pages.slice(page - 6, page + 5);
  }

  return (
    <>
      {pages.length !== 1 &&
        <div className='pagination'>
          <ul className='pages-list-pagination'>
            {page !== 1 && <li onClick={() => changePage(page - 1)} className='page-switch page'>{`< ${prevPage}`}</li>}
            {pages.map((pageNumber) => (
              <li
                onClick={() => changePage(pageNumber)} key={pageNumber}
                className={`
                  page
                  ${pageNumber === page ? 'page-active' : 'not-active'}
                `}
              >{pageNumber}</li>
            ))}
            {page !== pages.length && <li onClick={() => changePage(page + 1)} className='page-switch page'>{`${nextPage} >`}</li>}
          </ul>
        </div>
      }
    </>
  );
};

export default Pagination;

Pagination.propTypes = {
  page: PropTypes.number,
  changePage: PropTypes.func,
  pages: PropTypes.array
};
